import React from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';

import { sendRequest, triggerEvent } from '../../helpers/global';

import DateInput from '../input/DateInput';

import '../../sass/components/AdminToolsView.scss';

class ActsExport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      from: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
      to: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
    }
  }
  
  componentDidMount = () => {
  }

  requestUserReport = () => {
    const { from, to } = this.state;
    sendRequest({
      method: 'export/acts',
      type: 'POST',
      data: {
        from,
        to
      },
      success: (data) => {
        triggerEvent('showSnackbar', [{text: 'Архив формируется и будет отправлен на ваш почтовый адрес', type: 'success'}]);
      },
      error: (data) => {
      }
    });
  }

  render = () => {
    const { from, to } = this.state;
    return (
      <div className='toolSection'>
        <div className='toolName'>Архив актов</div>
        <div className='dateRange'>
          <label>Дата обновления с</label>
          <DateInput
            properties={{
              type: 'date'
            }}
            object={from}
            onChange={(k, value) => this.setState({from: value})}
          />
          <label>до</label>
          <DateInput
            properties={{
              type: 'date'
            }}
            object={to}
            onChange={(k, value) => this.setState({to: value})}
          />
        </div>
        <button
          disabled={!from || !to}
          onClick={this.requestUserReport}
        >Сформировать архив</button>
      </div>
    )
  }
}

export default (withRouter(ActsExport));
